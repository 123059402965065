import Vue from 'vue'
import { BModal } from 'bootstrap-vue/components/modal'
import { i18n } from '@/i18n'

const translator = i18n.t.bind(i18n)

/*
 * this mixin extends a component (ie. root)
 *
 * it attaches a modal instance to the DOM, provides 2 functions
 * this.$root.confirm, this.$root.alert
 *
 * which behave the same way as the window.* variants, exeptt they return a resolved Promise(Boolean)
 * the boolen is false in case of confirm:cancel
 */

const modalDefaultProps = {
  text: '',
  title: '',
  centered: true,
  noFade: true,
  noCloseOnEsc: true,
  noCloseOnBackdrop: true,
  hideHeaderClose: true,
  okVariant: 'primary',
  okOnly: false,
  returnFocus: 'body'
}

const ModalComponent = Vue.extend(BModal)
const theModal = new ModalComponent({ propsData: modalDefaultProps })

// for testing, block due to window.alert and similar
// eslint-disable-next-line
async function primitive(type, args) {
  if (typeof args === 'object' && args !== null) {
    const { title, text } = args
    return Promise.resolve(window[type](title + '\n' + text))
  } else {
    return Promise.resolve(window[type](args))
  }
}

function fancy (type, args) {
  let resolver
  const p = new Promise((resolve) => {
    resolver = resolve
  })

  const defaults = {
    title: type === 'alert' ? translator('alert.title') : translator('confirm.title'),
    okVariant: type === 'confirm' ? 'danger' : 'primary',
    okOnly: type === 'alert'
  }
  const eventData = {
    ok: resolver.bind(null, true),
    cancel: resolver.bind(null, false),
    ...defaults,
    ...(typeof args === 'object' && args !== null ? args : { text: args })
  }

  this.$log.debug('fancy', type, eventData)
  this.$emit('update-alert', eventData)
  return p.catch(() => {})
}

export default {
  methods: {
    alert (args) {
      return fancy.call(this, 'alert', args)
    },
    confirm (args) {
      return fancy.call(this, 'confirm', args)
    },
    onUpdateAlert (props) {
      for (const name in props) {
        if (name in modalDefaultProps) theModal[name] = props[name]
      }

      // split text by newlines and produce a div for each "paragraph"
      const body = props.text
        .split(/\n+/)
        .map(t => this.$createElement('div', t))

      // add to default slot which is the modal body
      theModal.$slots.default = [this.$createElement('div', {}, body)]

      // attach action listeners
      theModal.$once('ok', props.ok)
      theModal.$once('cancel', props.cancel)

      theModal.$once('hidden', () => {
        // remove listeners
        theModal.$off('ok', props.ok)
        theModal.$off('cancel', props.cancel)
      })
      theModal.show()
    }
  },
  created () {
    this.$on('update-alert', this.onUpdateAlert)
  },
  mounted () {
    const alertContainer = document.createElement('div')
    this.$el.appendChild(alertContainer)
    theModal.$mount(alertContainer)
  }
}
