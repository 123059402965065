import ApiService from './ApiService.js'

export default {
  get (slug) {
    return ApiService.get('contracts', slug)
  },

  find (params, subresource = '') {
    return ApiService.get('contracts', subresource, params)
  },

  pdf (id, locale = 'de', params) {
    return ApiService.get('contracts', `${id}/pdf/${locale}`, params)
  },

  put (payload, slug = []) {
    return ApiService.put('contracts', slug, { payload })
  },

  post (payload) {
    return ApiService.post('contracts', { payload })
  },

  createAnnex (id) {
    return ApiService.post(`contracts/${id}/annex`)
  },

  getAnnexList (id) {
    return ApiService.get('contracts', `${id}/annex-list`)
  },

  trash (id) {
    return ApiService.trash('contracts', id)
  },

  untrash (id) {
    return ApiService.untrash('contracts', id)
  },

  delete (id) {
    return ApiService.delete('contracts', id)
  }
}
