import Vue from 'vue'

import { map, filter, debounce, get, isError } from 'lodash'
import { ApiService } from '@/common/api'
import platform from 'platform'

import md5 from 'tiny-hashes/md5'
import moment from 'moment'
import StackTrace from 'stacktrace-js'

class SimpleError {
  #originalError

  constructor (message, source = '', original = {}) {
    this.message = message
    this.source = source
    this.stack = original.enhancedStack || original.stack || ''
    this.route = window.location.pathname

    this.request = {
      url: get(original, 'config.url'),
      method: get(original, 'config.method'),
      status: get(original, 'response.status')
    }

    this.id = md5(message + source)

    this.#originalError = original
  }

  get originalError () {
    return this.#originalError
  }

  toString () {
    return `[${this.id}] ${this.message}`
  }
}

class NativeError extends SimpleError {
  constructor (msg, url, line, col, error) {
    super(msg, `${url}#L${line}:${col}`, error)
    this.name = 'NativeError'
  }
}

class VueError extends SimpleError {
  constructor (err, vm, info) {
    super(`${err.name}: ${err.message} [${info}]`, VueError.getComponentPath(vm).join('/'), err)
    this.name = 'VueError'
    this.route = vm.$route.fullPath
  }

  static getComponentPath (vm) {
    const ancestorsAndSelf = [vm]
    while ((vm = vm.$parent) && vm) { ancestorsAndSelf.unshift(vm) }
    return filter(map(ancestorsAndSelf, '$options._componentTag'))
  }
}

class UnhandledPromiseRejectionError extends SimpleError {
  constructor (reason, orig) {
    super(reason.message || reason, reason.source, orig)
    this.name = 'UnhandledPromiseRejectionError'
  }
}

function reportErrorToApi (err) {
  if (!PRODUCTION) {
    Vue.$log.warn('!PRODUCTION: suppressing reportErrorToApi call')
    return
  }
  const client = { time: moment().utc().unix() }
  const pf = { description: platform.description }
  ApiService
    .post('report-error', { error: err, platform: pf, client, version: APP_VERSION })
    .then((res) => { Vue.$log.info(`reported error to api: ${err.name}(${err.message})`, res); return res })
    .catch((axiosError) => { Vue.$log.fatal('unable to report error', err.originalError); return axiosError })
}

const THROTTLE_TIME = 5 * 1000
const handleError = debounce(reportErrorToApi, THROTTLE_TIME, { leading: true, trailing: false })

function stringifyStackFrames (stackframes) {
  return stackframes.map(function (sf) {
    return '  at ' + sf.toString()
  }).join('\n')
}

// attach
export function registerGlobalErrorHandler () {
  Vue.$log.debug(`registerGlobalErrorHandler: max 1 error every ${THROTTLE_TIME}ms`)

  Vue.config.errorHandler = async function (err, vm, info) {
    const isActualError = isError(err)
    if (isActualError) {
      err.enhancedStack = await StackTrace.fromError(err)
        .then(stringifyStackFrames)
        .catch(function (err) { Vue.$log.error(err.message) })
    }

    const vueError = new VueError(err, vm, info)
    !isActualError || Vue.$log.error(vueError)
    handleError(vueError)
    return true
  }

  window.onerror = function (msg, url, line, col, error) {
    const nativeError = new NativeError(msg, url, line, col, error)
    handleError(nativeError)
    return true // signal that its handled
  }

  window.onunhandledrejection = async function ({ reason }) {
    let wrapped
    if (reason instanceof Error && reason.stack) {
      reason.enhancedStack = await StackTrace.fromError(reason)
        .then(stringifyStackFrames)
        .catch(function (err) { Vue.$log.error(err.message) })

      wrapped = new SimpleError(reason.message, null, reason)
    } else {
      wrapped = reason
    }

    const unhandledPromiseRejectionError = new UnhandledPromiseRejectionError(wrapped, reason)
    handleError(unhandledPromiseRejectionError)
    return true // signal that its handled
  }
}
