export default {
  computed: {
    providerName () {
      if (this.type === 'fundamental' || this.type === 'test') {
        return this.$t('Auftraggeber')
      } else if (this.type === 'special') {
        if (this.subtype === 'cooperation') { return this.$t('Kooperationspartner') }
        return this.$t('Vertragspartner')
      } else return this.$t('Lizenzgeber')
    },
    consumerName () {
      if (this.type === 'fundamental' || this.type === 'test') {
        return this.$t('Auftragnehmer')
      } else if (this.type === 'special') {
        if (this.subtype === 'cooperation') { return this.$t('Kooperationspartner') }
        return this.$t('Vertragspartner')
      } else return this.$t('Lizenznehmer')
    }
  }
}
