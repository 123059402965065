import axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'

import { genericAxiosErrorHandler } from '@/common/utils/Error.js'
import { loadProgressBar } from '@/common/utils'
import { trimEnd, isEmpty, join, castArray } from 'lodash'

const http = axios.create({
  baseURL: SERVICE_URL,
  withCredentials: true,
  validateStatus: function (status) {
    return status < 400 // Reject only if the status code is greater than or equal to 500
  }
})

http.interceptors.response.use(null, genericAxiosErrorHandler)
setupCache(http, { cacheTakeover: false })

export async function clearCache () {
  await http.storage.clear()
}

loadProgressBar({ minimum: 0.15, easing: 'ease', speed: 200 }, http)

function toPath (resource, slug) {
  slug = castArray(slug)
  return trimEnd(resource + '/' + (isEmpty(slug) ? '' : join(slug, '/')), '/')
}

export default {
  async get (resource = '', slug = [], params = {}) {
    const path = toPath(resource, slug)
    return http.get(path, { params })
  },

  post (resource, params, config) {
    return http.post(`/${resource}`, params, config)
  },

  put (resource, slug, params, config) {
    const path = toPath(resource, slug)
    return http.put(path, params, config)
  },

  /**
   * soft deletes an item
   * @param  string resource endpoint
   * @param  Number|String slug typically the id
   * @return Promise
   */
  trash (resource, slug) {
    return http.delete(`/${resource}/${slug}`)
  },

  /**
   * undeletes an item
   * @param  string resource endpoint
   * @param  int|string slug typically the id
   * @return Promise
   */
  untrash (resource, slug) {
    return http.delete(`/${resource}/${slug}/revert`)
  },

  /**
   * permanently deletes an item
   * @param  string resource endpoint
   * @param  int|string slug typically the id
   * @return Promise
   */
  delete (resource, slug) {
    return http.delete(`/${resource}/${slug}/force`)
  }
}
