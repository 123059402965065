import Vue from 'vue'
import { abilitiesPlugin } from '@casl/vue'
import { AbilityBuilder } from '@casl/ability'

const abilities = AbilityBuilder
  .define((can) => {})

let resolved = false
let resolver
const loaded = new Promise((resolve, reject) => { resolver = resolve })
  .then((_) => { resolved = true })

Vue.use(abilitiesPlugin, abilities)

export function update (newAbilities) {
  abilities.update(newAbilities)
  Vue.$log.info('abilities updated')
  resolver(true)
}

export function isLoaded () {
  return resolved
}

export function whenLoaded () {
  return loaded
}

export default abilities
